.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #101010;
  min-height: 100%;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 40px;
  padding-left: 10%;
}
